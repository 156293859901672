import clsx from 'clsx'
import { PropsWithChildren } from 'react'

interface ContainerProps {
  transparent?: boolean
}

const Container: React.FC<PropsWithChildren<ContainerProps>> = ({
  children,
  transparent = false,
}) => {
  return (
    <div
      className={clsx(
        'max-w-[500px] md:max-w-full w-full mx-auto min-h-full',
        transparent ? 'bg-transparent' : 'bg-white'
      )}
    >
      {children}
    </div>
  )
}

export default Container
