import clsx from 'clsx'
import useBetterMediaQuery from 'hooks/useBetterMediaQuery'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { AuthContext } from 'providers/AuthProvider'
import { CartContext } from 'providers/CartProvider'
import React, { PropsWithChildren } from 'react'
import { RightActionType } from 'types'
import WifDropdown from '../WifDropdown'

interface NavBarSearchProps {
  handleSearch: (e: React.KeyboardEvent) => void
  searchQuery?: string
  handleQueryChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleQueryClear: () => void
}

interface NavBarProps {
  rightActions?: RightActionType[]
  simplified?: boolean
  title?: string
  secondary?: boolean
  withLogo?: boolean
  shadow?: boolean
  from?: string
  search?: NavBarSearchProps | boolean
  backButton?: boolean
  searchPlaceholder?: string
  searchPageHref?: string
  handleClose?: () => void
  webViewRightActions?: React.ReactNode
  noAppDropdown?: boolean
}

const NavBar: React.FC<PropsWithChildren<NavBarProps>> = ({
  children,
  rightActions = ['order'],
  simplified = false,
  title,
  secondary,
  withLogo = true,
  shadow = true,
  from,
  search,
  backButton = false,
  searchPlaceholder,
  searchPageHref = '/search',
  webViewRightActions = null,
  handleClose,
  noAppDropdown = false,
}) => {
  const router = useRouter()
  const { t } = useTranslation('navbar, common')
  const { cartCount } = React.useContext(CartContext)
  const { authToken } = React.useContext(AuthContext)
  const isMobile = useBetterMediaQuery('(max-width: 768px)')

  const handleBack = () => {
    // handle user coming from outside of wif
    if (!from) return router.push('/')

    // handle back button surfing inside wif
    if (
      from?.indexOf(window.location.origin) >= 0 &&
      (from.indexOf('/made-to-order') >= 0 ||
        from.indexOf('/wif-maas') >= 0 ||
        from.indexOf('/product') >= 0)
    ) {
      return router.back()
    }

    if (!authToken && from.indexOf('account')) return router.push('/')

    // handle special case on user logging out
    if (window.location.pathname.indexOf('login') >= 0) return router.push('/')

    // handle opened in new tab with no history
    if (window.history.length <= 1) return router.push('/')
    // handle default behavior
    return router.back()
  }

  return (
    <nav
      className={clsx(
        'flex gap-x-3 fixed top-0 px-4 items-center w-full z-50 bg-white max-w-full left-0 py-[10px]',
        shadow && 'shadow-[0px_7px_20px_0px_rgba(126,126,126,0.05)]',
        secondary ? 'bg-wif-orange grid-cols-[3fr_1fr] ' : 'grid-cols-[auto_1fr_auto]',
        isMobile && 'min-h-[70px]'
      )}
    >
      {simplified ? (
        <>
          {backButton ? (
            <div className='flex h-6 w-6 cursor-pointer' onClick={handleBack}>
              <Image src='/icons/ic-chevron-left_24x24.svg' alt='back' width={24} height={24} />
            </div>
          ) : (
            <div className='flex h-6 w-6 cursor-pointer' onClick={handleClose}>
              <Image src='/icons/ic-close.svg' alt='back' width={24} height={24} />
            </div>
          )}

          {search && (
            <div className='flex items-center p-3 pb-[10px] relative bg-ivory rounded-[10px] w-full focus-within:outline focus-within:outline-primary'>
              <Image src='/icons/ic-search.svg' alt='logo' width={14} height={14} priority />
              <input
                autoFocus
                type='text'
                value={(search as NavBarSearchProps).searchQuery}
                onChange={(search as NavBarSearchProps).handleQueryChange}
                onKeyUp={(search as NavBarSearchProps).handleSearch}
                placeholder={searchPlaceholder}
                className='bg-ivory outline-none pl-2 leading-[18px] w-full text-sm placeholder-[#9E9E9E] text-wif-black'
              />
              {(search as NavBarSearchProps).searchQuery && (
                <span
                  className='flex absolute right-2 cursor-pointer'
                  onClick={(search as NavBarSearchProps).handleQueryClear}
                >
                  <Image priority src='/icons/ic-close.svg' alt='close' width={20} height={20} />
                </span>
              )}
            </div>
          )}

          {!search && <h2 className='text-sm font-bold ml-[10px] py-[10px]'>{title}</h2>}
        </>
      ) : (
        <>
          {backButton && (
            <div className='flex h-6 w-6 cursor-pointer mr-auto' onClick={handleBack}>
              <Image src='/icons/ic-chevron-left_24x24.svg' alt='back' width={24} height={24} />
            </div>
          )}
          {withLogo && !isMobile && (
            <Link
              href={{ pathname: 'https://wifkain.com' }}
              passHref={true}
              className='shrink-0 mr-4'
            >
              <Image
                src='/icons/wif-logo.svg'
                alt='wif logo'
                width={105}
                height={56}
                priority
                style={{ width: 'auto', height: 'auto' }}
              />
            </Link>
          )}
          {!noAppDropdown && (
            <div className='flex mr-auto'>
              <WifDropdown />
            </div>
          )}

          {!isMobile && search && (
            <div className='flex items-center p-3 pb-[10px] bg-ivory rounded-[10px] w-full max-w-[500px]'>
              <Image src='/icons/ic-search.svg' alt='logo' width={14} height={14} priority />
              <Link href={searchPageHref} className='w-full'>
                <input
                  type='text'
                  placeholder={searchPlaceholder || t('common:Word_Search') || ''}
                  className='flex w-full bg-ivory outline-none pl-2 leading-[18px] text-sm placeholder-[#9E9E9E] text-wif-black'
                />
              </Link>
            </div>
          )}
        </>
      )}

      {isMobile && !simplified && (
        <Link
          href={{ pathname: 'https://wifkain.com' }}
          passHref={true}
          className='flex justify-center items-center fixed top-[19px] left-1/2 -translate-x-1/2'
        >
          <Image
            src='/icons/wif-logo.svg'
            alt='wif logo'
            width={71}
            height={27}
            className='inline-block'
            priority
          />
        </Link>
      )}

      {children}

      {rightActions && rightActions?.length > 0 && (
        <div className='grid grid-flow-col auto-cols-[minmax(0,max-content)] gap-3 justify-end ml-auto'>
          {isMobile && rightActions.includes('search') && (
            <Link href={searchPageHref} className='flex cursor-pointer relative'>
              <Image src='/icons/ic-search-wc.svg' alt='Search icon' width={30} height={30} />
            </Link>
          )}

          {isMobile && rightActions.includes('order') && (
            <div className='flex cursor-pointer relative' onClick={() => router.push('/cart')}>
              <Image
                src={secondary ? '/icons/ic-bag-white_32x32.svg' : '/icons/ic-bag-wc.svg'}
                alt='order'
                width={30}
                height={30}
              />
              {!!cartCount && (
                <span
                  className={clsx(
                    'absolute flex items-center justify-center  rounded-[4px] p-[2px] w-auto min-w-[14px] leading-none top-1 right-[2px] text-[10px] ',
                    secondary ? 'bg-white text-wif-orange' : 'bg-wif-orange text-white'
                  )}
                >
                  {cartCount > 99 ? `99+` : cartCount}
                </span>
              )}
            </div>
          )}

          {isMobile && rightActions.includes('history') && (
            <div
              className='flex cursor-pointer relative'
              onClick={() => router.push('/wif-fin/history')}
            >
              <Image src='/icons/ic-bag-outline.svg' alt='Order Icon' width={30} height={30} />
            </div>
          )}
        </div>
      )}
      {!isMobile && <div className='flex ml-auto shrink-0'>{webViewRightActions}</div>}
    </nav>
  )
}

export default NavBar
