const dashboardDomain = process.env.NEXT_PUBLIC_DASHBOARD_DOMAIN as string

export const RFQ_COLOR = {
  GREIGE: 'Greige',
  WHITE: 'White',
  BRIGHT: 'Bright',
  MEDIUM: 'Medium',
  DARK: 'Dark',
}

export const PROJECT_TYPE = {
  FOOTWEAR: 'footwear',
  CLOTHES: 'clothes',
  ACCESSORIES: 'accessories',
  OTHERS: 'others',
}

export const CLOTHES_SIZING = ['S', 'M', 'L', 'XL', 'XXL']
export const BABY_CLOTHES_SIZING = ['newBorn', '6mo', '9mo', '18mo', '24mo', '36mo']
export const WOMEN_FOOTWEAR_SIZING = ['36', '37', '38', '39', '40']
export const MAN_FOOTWEAR_SIZING = ['38', '39', '40', '41', '42', '43', '44']
export const KID_FOOTWEAR_SIZING = [
  '27',
  '28',
  '29',
  '30',
  '31',
  '32',
  '33',
  '34',
  '35',
  '36',
  '37',
]

export const ACCESSORIES_VARIETY_OPTIONS = [
  { name: 'service:Options_Hat', value: 'Topi' },
  { name: 'service:Options_Hijab', value: 'Hijab' },
  { name: 'service:Options_Bag', value: 'Tas' },
  { name: 'service:Options_Pouch', value: 'Pouch' },
  { name: 'service:Options_Apron', value: 'Apron' },
  { name: 'service:Options_Ciput', value: 'Ciput' },
  { name: 'service:Options_Wallet', value: 'Dompet' },
  { name: 'service:Options_Towel', value: 'Handuk' },
  { name: 'service:Options_Mask', value: 'Masker' },
  { name: 'service:Options_PrayerHijab', value: 'Mukena' },
  { name: 'service:Options_PrayerMat', value: 'Sajadah' },
  { name: 'service:Options_PrayerCover', value: 'Sarung' },
  { name: 'service:Options_Glove', value: 'Sarung Tangan' },
  { name: 'service:Options_Belt', value: 'Ikat Pinggang' },
  { name: 'service:Options_Others', value: 'Lainnya' },
]

export const FOOTWEAR_VARIETY_OPTIONS = [
  { name: 'service:Options_Sandal', value: 'Sandal' },
  { name: 'service:Options_Shoes', value: 'Sepatu' },
  { name: 'service:Options_KidSocks', value: 'Kaos Kaki Anak' },
  { name: 'service:Options_Boots', value: 'Boots' },
  { name: 'service:Options_Socks', value: 'Kaos Kaki' },
  { name: 'service:Options_FlipFlop', value: 'Sandal Jepit' },
]

export const CLOTHES_VARIETY_OPTIONS = [
  { name: 'service:Options_Shirt', value: 'Kemeja' },
  { name: 'service:Options_Tshirt', value: 'Kaos' },
  { name: 'service:Options_Blouse', value: 'Blouse' },
  { name: 'service:Options_Pants', value: 'Celana' },
  { name: 'service:Options_Jeans', value: 'Jeans' },
  { name: 'service:Options_Dress', value: 'Dress' },
  { name: 'service:Options_Skirt', value: 'Skirt' },
  { name: 'service:Options_Abaya', value: 'Abaya' },
  { name: 'service:Options_APD', value: 'APD' },
  { name: 'service:Options_KidsClothing', value: 'Baju Anak' },
  { name: 'service:Options_KidsJacket', value: 'Kemeja Anak' },
  { name: 'service:Options_KidsCardigan', value: 'Cardigan Anak' },
  { name: 'service:Options_TankTop', value: 'Tank Top' },
  { name: 'service:Options_KnitSet', value: 'Knit Set' },
  { name: 'service:Options_SportPants', value: 'Celana Olahraga' },
  { name: 'service:Options_Coat', value: 'Jaket Musim Dingin' },
  { name: 'service:Options_Cardigan', value: 'Cardigan' },
  { name: 'service:Options_LongPants', value: 'Celana Panjang' },
  { name: 'service:Options_Shorts', value: 'Celana Pendek' },
  { name: 'service:Options_DenimJacket', value: 'Jaket Denim' },
  { name: 'service:Options_Chinos', value: 'Chinos' },
  { name: 'service:Options_Swimwear', value: 'Baju Renang' },
  { name: 'service:Options_Crewneck', value: 'Crewneck' },
  { name: 'service:Options_Daster', value: 'Daster' },
  { name: 'service:Options_Tunic', value: 'Gamis' },
  { name: 'service:Options_Hoodie', value: 'Hoodie' },
  { name: 'service:Options_PoloShirt', value: 'Polo Shirt' },
  { name: 'service:Options_VarsityJacket', value: 'Jaket Varsity' },
  { name: 'service:Options_LeatherJacket', value: 'Jaket Kulit' },
  { name: 'service:Options_Raincoat', value: 'Jas Hujan' },
  { name: 'service:Options_Jumpsuit', value: 'Jumpsuit' },
  { name: 'service:Options_Koko', value: 'Koko' },
  { name: 'service:Options_Legging', value: 'Legging' },
  { name: 'service:Options_Lingerie', value: 'Lingerie' },
  { name: 'service:Options_Cuff', value: 'Manset Tangan' },
  { name: 'service:Options_Outer', value: 'Outerwear' },
  { name: 'service:Options_Piyama', value: 'Piyama' },
  { name: 'service:Options_Vest', value: 'Rompi' },
  { name: 'service:Options_Uniform', value: 'Seragam' },
  { name: 'service:Options_SportShirt', value: 'Kaos Olahraga' },
  { name: 'service:Options_Underwear', value: 'Celana Dalam' },
  { name: 'service:Options_Blazer', value: 'Blazer' },
  { name: 'service:Options_Parka', value: 'Jaket Parka' },

  { name: 'service:Options_Others', value: 'Lainnya' },
]

export const DEFAULT_TABBAR_LIST = [
  {
    title: 'Shop',
    href: '/',
    key: 'shop',
    normalIcon: '/icons/ic-bag_32x32.svg',
    activeIcon: '/icons/ic-bag_32x32-active.svg',
  },
  {
    title: 'Explore',
    href: '/explore',
    key: 'explore',
    normalIcon: '/icons/ic-explore_32x32.svg',
    activeIcon: '/icons/ic-explore_32x32-active.svg',
  },
  {
    title: 'My Orders',
    href: '/orders',
    key: 'orders',
    normalIcon: '/icons/ic-order_32x32.svg',
    activeIcon: '/icons/ic-order_32x32-active.svg',
  },
]

export const WIF_SERVICE_TABBAR_LIST = [
  {
    title: 'Manufacture',
    href: '/wif-maas',
    key: 'service-home',
    normalIcon: '/icons/ic-maas_32x32.svg',
    activeIcon: '/icons/ic-maas_32x32-active.svg',
  },
  {
    title: 'Pesanan',
    href: dashboardDomain,
    key: 'servicer-orders',
    normalIcon: '/icons/ic-bill_32x32.svg',
    activeIcon: '/icons/ic-bill_32x32-active.svg',
  },
  {
    title: 'Bantuan',
    href: '/wif-maas/help',
    key: 'service-help',
    normalIcon: '/icons/ic-chat_32x32.svg',
    activeIcon: '/icons/ic-chat_32x32-active.svg',
  },
]

export const WIF_FIN_TABBAR_LIST = [
  {
    title: 'Finance',
    href: '/wif-fin',
    key: 'fin-home',
    normalIcon: '/icons/ic-finance_32x32.svg',
    activeIcon: '/icons/ic-finance_32x32-active.svg',
  },
  {
    title: 'Pengajuan',
    href: '/wif-fin/history',
    key: 'fin-application',
    normalIcon: '/icons/ic-bill_32x32.svg',
    activeIcon: '/icons/ic-bill_32x32-active.svg',
  },
  {
    title: 'Bantuan',
    href: '/wif-maas/help',
    key: 'fin-help',
    normalIcon: '/icons/ic-chat_32x32.svg',
    activeIcon: '/icons/ic-chat_32x32-active.svg',
  },
]

export const ORDER_STATUS_TYPE = {
  INQUIRY: 'Inquiry',
  IN_PROGRESS: 'In Progress',
  DELIVERY: 'Delivery',
  DONE: 'Done',
  CANCELLED: 'Cancelled',
}

export const APPLICATION_STATUS_TYPE = {
  DRAFT: 'Draft',
  SUBMITTED: 'Submitted',
  IN_REVIEW: 'In Review',
  IN_PROGRESS: 'In Progress',
  APPROVED: 'Approved',
  APPROVAL: 'Approval',
  REJECTED: 'Rejected',
  NEED_TO_REVISE: 'Need to Revise',
  VIRTUAL_MEET: 'Scheduled Virtual Meet',
  ACTIVE: 'Active',
  SCHEDULED_MEET: 'Scheduled Virtual Meet',
  PENDING: 'Pending',
}

const linkedItemTable = '(from Items)'
export const INQUIRIES_ITEM_FIELD_NAME = {
  TECH_PACK: `Tech Pack ${linkedItemTable}`,
  ITEM_NAME: `Item Name ${linkedItemTable}`,
  QUANTITY: `Quantity ${linkedItemTable}`,
  UNIT: `Unit ${linkedItemTable}`,
  TARGET_PRICE: `Target Price/pc from Customer ${linkedItemTable}`,
  STATUS: `Status ${linkedItemTable}`,
}

export const WifErrorCode = {
  MAX_FILE_SIZE: 'E-01',
}
export const WifErrorMessage = {
  MAX_FILE_SIZE: 'There is file with size more than 5MB',
}

export const MAAS_ITEM_STATUS = {
  DRAFT: 'Draft',
  ENQUIRY: 'Enquiry',
  RFQ: 'RFQ',
  QUOTATION_CONFIRMATION: 'Quotation Confirmation',
  SAMPLING_1: 'Sampling 1',
  PPS: 'PPS',
  DOWN_PAYMENT: 'Down Payment',
  PPM: 'PPM',
  PRODUCTION: 'Production',
  QC_FINAL_REPORT: 'QC Final Report',
  FINAL_PAYMENT: 'Final Payment',
  DELIVERY: 'Delivery',
  REVISION: 'Revision',
  COMPLETED: 'Completed',
}

export const MAAS_ONBOARDING_FLAG = 'finished-maas-onboarding'
export const FIN_ONBOARDING_FLAG = 'finished-fin-onboarding'
