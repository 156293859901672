import { Menu, Transition } from '@headlessui/react'
import clsx from 'clsx'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Fragment } from 'react'

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ')
}

const WifDropdown: React.FC<{}> = ({}) => {
  const router = useRouter()
  const renderTitle = () => {
    if (router.pathname.includes('wif-fin')) return 'Finance'
    if (router.pathname.includes('wif-maas')) return 'Manufacture'
    if (router.pathname.includes('/')) return 'Textile'
  }
  return (
    <Menu as='div' className='relative flex min-w-[120px]'>
      <div>
        <Menu.Button className='inline-flex w-full justify-center gap-x-1.5 bg-white text-sm font-semibold text-gray-900'>
          {renderTitle()}
          <Image src='/icons/ic-chevron-down.svg' alt='Open Icon' width={20} height={20} />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className='absolute right-100 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
          <div className='p-2'>
            <MenuItem
              title='Manufacture'
              url='https://wifkain.com/maas'
              logoUrl='/icons/ic-maas_35x35.svg'
              alt='Maas Logo'
            />
            <MenuItem
              title='Textile'
              url='/'
              logoUrl='/icons/ic-textile_35x35.svg'
              alt='Textile Logo'
            />
            <MenuItem
              title='Finance'
              url='https://wifkain.com/finance'
              logoUrl='/icons/ic-fin_35x35.svg'
              alt='Finance Logo'
            />
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

const MenuItem: React.FC<{ title: string; url: string; logoUrl: string; alt: string }> = ({
  title,
  url,
  logoUrl,
  alt,
}) => {
  const router = useRouter()
  const selected = (title: string) => {
    if (router.pathname.includes('wif-fin')) return title === 'Finance'
    if (router.pathname.includes('wif-maas')) return title === 'Manufacture'
    if (router.pathname.includes('/')) return title === 'Textile'
  }
  return (
    <Menu.Item>
      {({ active }) => (
        <Link
          href={url}
          className={classNames(
            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
            'px-4 py-2 text-sm flex items-center gap-3 hover:bg-[#FFFBF4] rounded-lg'
          )}
        >
          <Image src={logoUrl} alt={alt} width={35} height={35} />
          {title}
          <div
            className={clsx(
              'rounded-full border border-[#DEDEDF] w-[24px] h-[24px] ml-auto relative',
              selected(title)
                ? 'border-primary after:absolute after:rounded-full after:w-4 after:h-4 after:bg-primary after:top-1/2 after:-translate-x-1/2 after:left-1/2 after:-translate-y-1/2'
                : ''
            )}
          ></div>
        </Link>
      )}
    </Menu.Item>
  )
}

export default WifDropdown
